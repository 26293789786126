import React from "react"
import Layout from "../components/index/layout"
import SEO from "../components/index/seo"
import { Row, Col, Card, Container } from "react-bootstrap"
import Header from "../components/index/Header"
import { Link } from "gatsby"
import image1 from "../images/service1.png"
import image2 from "../images/Service3.png"
import image3 from "../images/app-3.png"
import arrow from "../images/redArrow.png"

import "../components/services/Services.css"

const IndexPage = () => (
  <Layout>
    <Header />
    <SEO
      title="Services"
      keywords={[
        `SimpleReport`,
        `SimpleReport.ca`,
        `reporting`,
        `report`,
        `safety`,
        `OHS`,
        `OSHA`,
        `OHS-reporting`,
        `OSHA-reporting`,
        `invoices`,
        `invoicing`,
        `ERP`,
        `Occupational Health and Safety`,
        `EHS`,
        `Environmental Health and Safety`,
      ]}
    />
    <div id="servicePage">
      <Row id="firstRowServicePage" className="serviceRow">
        <Col sm={12} lg={5} className="d-flex align-items-center" id="alignmentAdjust">
          <Container>
            <Col lg={10} className="servicePageText">
              <h5>
                Simple Report offers a comprehensive range of cost-effective and
                efficient health and safety consulting and training services
                that are tailored to each client's requirements.{" "}
              </h5>
              <p>
                We provide health and safety support for companies starting from
                strategic consultancy, initial risk assessments and project
                start up, to on-site support and training and ultimately to
                examination and identification of the wide range of health and
                safety challenges that can be encountered on a daily basis
                during operations.
              </p>
              <br />
              <Link to="/contact-us">
                <button className="servicePageButton">
                  Contact us for more information <img src={arrow} />
                </button>
              </Link>
            </Col>
          </Container>
        </Col>
        <Col sm={12} lg={7} className="d-none d-lg-block p-0">
          <Card
            className="text-white blend"
            border="none"
            id="serviceFirstImage"
          >
            <Card.Img src={image1} alt="Card image" />
          </Card>
        </Col>
      </Row>
      <Row id="secondRowServicePage" className="serviceRow pt-3 pb-3">
        <Col sm={12} md={6} lg={6} className="d-none d-lg-block image-block">
          <div className="text-white blend d-flex" border="none">
            <Card.Img
              className="m-auto"
              id="serviceAppImage"
              src={image3}
              alt="Card image"
            />
          </div>
        </Col>
        <Col sm={12} md={12} lg={5} className="d-flex">
          <Container className="d-flex align-items-center">
            <Col lg={10} className="servicePageText">
              <h5>
                Assurance Database Management for occupational health safety
              </h5>
              <br />
              <p>
                SimpleReport's offers digital assurance database management for
                clients who want to streamline HSE monitoring.
              </p>
              <br />
              <Link to="/">
                <button className="servicePageButton d-flex align-items-center justify-content-between">
                  Learn more about digital services
                  <img src={arrow} />
                </button>
              </Link>
            </Col>
          </Container>
        </Col>
      </Row>
      <Row id="thirdRowServicePage" className="serviceRow">
        <Col sm={12} md={12} lg={5} className="d-flex bottomColumnHomePage">
          <Container className="d-flex align-items-center">
            <Col lg={10} className="servicePageText">
              <h5>
                Bring the power of Artificial Intelligence into your everyday
                operations
              </h5>
              <br />
              <p>
                SimpleReport leverages the power of Artificial Intelligence and
                Machine Learning to improve occupational safety by automating
                HSE data analysis.
              </p>
              <br />
              <Link to="/">
                <button className="servicePageButton">
                  Learn more about data analytics
                  <img src={arrow} />
                </button>
              </Link>
            </Col>
          </Container>
        </Col>
        <Col sm={12} md={6} lg={7} className="d-none d-lg-block">
          <Card
            className="text-white blend"
            border="none"
            style={{
              border: "none",
              backgroundColor: "transparent",
              display: "flex",
            }}
          >
            <Card.Img id="" src={image2} alt="Card image" />
          </Card>
        </Col>
      </Row>
    </div>
  </Layout>
)

export default IndexPage
